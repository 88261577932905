import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';


export default function ViewHeaderProjectLogo () { 

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "header/logo--greater-avalon.png"}) {
        childImageSharp {
          fixed(width: 320, height: 95) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }  
  `)

  return (
    <Img fixed={ data.file.childImageSharp.fixed } alt='Greater Avalon logo' />
  );

}

