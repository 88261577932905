
import React from 'react';
import { Link } from 'gatsby'
import ViewHeaderCompanyLogo from './ViewHeaderCompanyLogo';
import ViewHeaderProjectLogo from './ViewHeaderProjectLogo';
import ButtonHeaderSubMenu from './ButtonHeaderSubMenu';
import { dataHeader } from './dataHeader';
import styles from './stylesHeader.module.scss';
// import AniLink from 'gatsby-plugin-transition-link/AniLink'


export default function ViewHeader () {

    const getLinks = () => {
        const links = [];
        let currRoute;
        let currKeyIndex = 0;
        for (let i = 0; i < dataHeader.length-1; i++) { //skip home as it's used as the logo
            currRoute = dataHeader[i];
            links.push(
                <li className={ styles.navListItem } key={ currKeyIndex }>
                    {
                    currRoute.routes === undefined //whether it's a drop down or not
                    ? <Link className={ styles.link } activeClassName={ styles.linkSelected } to={ currRoute.path }>{ currRoute.title }</Link>
                    : <ButtonHeaderSubMenu title={ currRoute.title } options={ currRoute.routes } />
                    }
                </li>
            );
            currKeyIndex++;
        }
        return links;
    }

    return (
        <header className={ styles.header }>
            <Link to='/'>
                <ViewHeaderProjectLogo />
            </Link>
            <div className={ styles.navList }>
                <ul>
                    { getLinks() }
                </ul>
            </div>
            <ViewHeaderCompanyLogo />
        </header>
    );

}