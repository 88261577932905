


export const dataHeader = [
    {
        title: 'The Catalyst',
        routes: [
            {
                title: 'Site Location',
                path: '/site-location/'
            },
            {
                title: 'MAB',
                path: '/mab/'
            },
            {
                title: 'Strategic Alignment',
                path: '/strategic-alignment/'
            },
            {
                title: 'Economic Impacts',
                path: '/economic-impacts/'
            }
        ]
    },
    {
        title: 'The Opportunity',
        routes: [
            {
                title: 'Overview',
                path: '/overview/'
            },
            {
                title: 'The Ecosystem',
                path: '/the-ecosystem/'
            },
            {
                title: 'Key Industries',
                path: '/key-industries/'
            },
        ]
    },
    {
        title: 'Masterplan',
        routes: [
            {
                title: 'Masterplan',
                path: '/masterplan/'
            },
            {
                title: 'Our Places',
                path: '/our-places/'
            }
        ]
    },
    {
        title: 'Outcomes',
        routes: [
            {
                title: 'Local Jobs',
                path: '/local-jobs/'
            },
            {
                title: 'Greater Liveability',
                path: '/greater-liveability/'
            },
            {
                title: 'Transport Gateway',
                path: '/transport-gateway/'
            },
            {
                title: 'Super Users',
                path: '/super-users/'
            },
            {
                title: 'Freight State',
                path: '/freight-state/'
            },
            {
                title: '21st Century Business Park',
                path: '/21st-century-business-park/'
            },
            {
                title: 'Environment',
                path: '/environment/'
            }
        ]
    },
    {
        title: 'Making it happen',
        routes: [
            {
                title: 'Next Steps',
                path: '/next-steps/'
            },
            {
                title: 'Programme',
                path: '/programme/'
            }
        ]
    },
    {
        title: 'Gallery',
        routes: [
            {
                title: 'Renders',
                path: '/renders/'
            },
            {
                title: 'Aerial Photos',
                path: '/aerial-photos/'
            },
            {
                title: 'MAB Video',
                path: '/mab-video/'
            }
        ]
    },
    {
        title: 'Home',
        path: '/'
    }
];