
import React, { useRef, useEffect, useState } from 'react';
import sha256 from 'crypto-js/sha256';
import ViewHeader from './header/ViewHeader';
import styles from './stylesMain.module.scss';


export default function ViewMain ( { children } ) {

	const updateDimensions = () => { //fills and centers the 1920 x 1080 according to the current browser dimensions, sitting in the middle vertically and at the top

		let amountImgOffset;
		let currScaleFactor;
		const windowWidth = window.innerWidth; 
		const windowHeight = window.innerHeight;
		const windowAspectRatio = windowWidth / windowHeight;
		const appAspectRatio = 1920 / 1080;
			
		containerRef.current.style.marginLeft = '0px'; //reset for another positon
		// containerRef.current.style.marginTop = '0px'; //reset for another positon
      
		if (windowAspectRatio < appAspectRatio) { 
			currScaleFactor = windowWidth / 1920;
			containerRef.current.style.transform = 'scale(' + currScaleFactor + ')';
			// containerRef.current.style.marginTop = ''
			// amountImgOffset = (containerRef.current.getBoundingClientRect().height - windowHeight) / 2; //to vertically align
			// containerRef.current.style.marginTop = -amountImgOffset + 'px'; 

		} else {
			currScaleFactor = windowHeight / 1080;
			containerRef.current.style.transform = 'scale(' + currScaleFactor + ')';
			amountImgOffset = (containerRef.current.getBoundingClientRect().width - windowWidth) / 2; //to horiztonally align
			containerRef.current.style.marginLeft = -amountImgOffset + 'px';
			
		}

  	}

  	const containerRef = useRef(null);
  	useEffect(() => {
      	if (containerRef.current) {
        	updateDimensions();
      	}
      	const handleResize = () => {
        	updateDimensions();
      	}
      	window.addEventListener('resize', handleResize);
      	return () => {
          	window.removeEventListener('resize', handleResize);
      	}
	}, [containerRef]);
	
	const [ isLoggedIn, setIsLoggedIn ] = useState(false);
	const [ inputPassword, setInputPassword ] = useState('');
	const password = '86732df8931509bcb0acd4168197a2b2015323eef4d1e530f0ca6bc91c8c2e74'; //sha-256 hash
	const refStatus = useRef(null);
	const onPasswordChange = (event) => {
		const newPassword = event.target.value;
		setInputPassword(newPassword);
	}
	const onSubmitPassword = (event) => {
		event.preventDefault();
		const hashDigest = sha256(JSON.stringify(inputPassword)).toString();
		if (hashDigest === password) {
			setIsLoggedIn(true);
			refStatus.current.style.display = 'none';
		} else {
			refStatus.current.style.display = 'block';
		}
	}

	const showLoggedIn = () => {
		return (
			<>
				<ViewHeader />
          		<main>{ children }</main>
			</>
		);
	}

	const showLogin = () => {
		return (
			<div className={ styles.loginContainer }>
				<form onSubmit={ onSubmitPassword }>
					<label htmlFor="pwd">Password</label>
					<input type="password" value={ inputPassword } onChange={ onPasswordChange }></input>
					<p ref={ refStatus }>Invalid password</p>
					<button type="submit"  value="Submit">Submit</button>
				</form>
			</div>
		)
	}

  	return (
    	<>
      		<div className={ styles.mainContainer } ref={ containerRef }>
			 	{ isLoggedIn ? showLoggedIn() : showLogin() }
      		</div>
    	</>
  	);

}

