
import React, { useRef, useEffect } from 'react';
import { Link } from 'gatsby'
import { gsap } from 'gsap/all';
import styles from './stylesHeader.module.scss';
// import AniLink from 'gatsby-plugin-transition-link/AniLink'


export default function ButtonHeaderSubMenu ( { title, options } ) {

    const containerRef = useRef(null);
    const btnRef = useRef(null);
    const subMenuRef = useRef(null);
    let isMenuOpen = false;


    useEffect(() => {
        const hasMatch = options.some(option => option.path.replaceAll('/', '') === window.location.pathname.replaceAll('/', ''));
        if (hasMatch) {
            // btnRef.current.classList.add('linkSelected');
            btnRef.current.style.color = '#FFF';
        } else {
            btnRef.current.style.color = '#69c9ee';
        }
    });

    useEffect(() => {
        const btnWidth = parseFloat(getComputedStyle(btnRef.current, null).width.replace("px", ""));
        const subMenuWidth = parseFloat(getComputedStyle(subMenuRef.current, null).width.replace("px", ""));
        let difference;
        if (subMenuWidth >= btnWidth){
            difference = Math.round(subMenuWidth - btnWidth);
            subMenuRef.current.style.left = -(difference/2) + 'px'; //to vertically align the submenu with the link
        } else {
            difference = Math.round(btnWidth - subMenuWidth);
            subMenuRef.current.style.left = (difference/2) + 'px'; //to vertically align the submenu with the link
        }
    });
    
    const getSubMenuOptions = () => {
        const optionsList = [];
        let option;
        for (let i = 0; i < options.length; i++) { 
            option = options[i];
            optionsList.push(
                <li key={i}>
                    <Link className={ styles.linkInSubMenu } activeClassName={ styles.linkSelected } to={ option.path }>{ option.title }</Link>
                </li>
            );
        }
        return optionsList;
    }

    const openSubMenuOnHover = (event) => {
        event.preventDefault();
        if (!isMenuOpen) {
            isMenuOpen = true;
            subMenuRef.current.style.display = 'block';
            subMenuRef.current.style.top = '75%';
            subMenuRef.current.style.opacity = 0;
            gsap.to(subMenuRef.current, 0.3, { top: '99%', opacity: 1 } );
            containerRef.current.addEventListener('mouseleave', onMouseLeaveContainer);
        }
    }

    const openSubMenuOnClick = (event) => {
        event.preventDefault();
        if (!isMenuOpen) {
            isMenuOpen = true;
            subMenuRef.current.style.display = 'block';
            subMenuRef.current.style.top = '75%';
            subMenuRef.current.style.opacity = 0;
            gsap.to(subMenuRef.current, 0.3, { top: '99%', opacity: 1 } );
            document.addEventListener('click', onDocumentClick);
        }
    }

    const onDocumentClick = (event) => {
        closeSubMenu();
    }

    const onMouseLeaveContainer = (event) => {
        closeSubMenu();
    }

    const closeSubMenu = (event) => {
        if (isMenuOpen) {
            isMenuOpen = false;
            containerRef.current.removeEventListener('mouseleave', onMouseLeaveContainer);
            document.removeEventListener('click', onDocumentClick);
            subMenuRef.current.style.display = 'none';
        }
    }

    return (
        <div ref={ containerRef }>
            <a className={ styles.link } ref={ btnRef } onMouseEnter={ openSubMenuOnHover } onClick={ openSubMenuOnClick } href='#toggle-menu'>{ title }</a>
            <div className={ styles.subMenu } ref={ subMenuRef }>
                <ul>
                    { getSubMenuOptions() }
                </ul>
            </div> 
        </div> 
    );

}