import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styles from './stylesHeader.module.scss';


export default function ViewHeaderCompanyLogo () { 

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "header/logo--mab.png"}) {
        childImageSharp {
          fixed(width: 211, height: 95) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }  
  `)

  return (
    <a className={ styles.mabLogo } href='https://www.mab.com.au/' target='_blank' rel='noreferrer'>
      <Img  fixed={ data.file.childImageSharp.fixed } alt='Developed by MAB' />
    </a>
  );

}

